import { LossePlaatjie } from '@ubo/losse-sjedel'
import AnimateInView from '~/components/elements/AnimateInView'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBackgroundImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentBackgroundImage" className="section p-0 sm:h-[400px] lg:h-[700px]">
      <div className="relative h-full w-full max-w-[2000px] mx-auto">
        {fields?.image && (
          <div className="absolute top-0 left-0 h-full w-full -z-10">
            <LossePlaatjie className="w-full h-full object-cover" src={fields.image} maxwidth={2000} />
          </div>
        )}
        <div className="container h-full max-sm:py-8 flex items-center justify-end">
          <AnimateInView>
            <div className="p-6 lg:p-9 bg-blitz-sand h-fit max-md:w-full md:w-[535px]">
              <div>
                <div className="title--normal mb-6">{fields?.title}</div>
                {fields.description && <Content className="content children-p:font-tiny">{fields?.description}</Content>}
              </div>
              <Links className="mt-6 max-lg:flex-col" fields={fields} type="alt" />
            </div>
          </AnimateInView>
        </div>
      </div>
    </section>
  )
}
