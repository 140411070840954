import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import AnimateInView from '~/components/elements/AnimateInView'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImage" className="section lg:py-24">
      <AnimateInView>
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-24 items-center lg:pl-16">
            <div className={clsx('col-span-1', fields?.imagePosition === 'left' && 'order-2')}>
              <h2 className="title mb-6">{fields?.title}</h2>
              <Content className="content mb-6 lg:mb-10">{fields?.description}</Content>
              <Links fields={fields} />
            </div>
            <div
              className={clsx('col-span-1 lg:flex max-lg:mt-6', fields?.imagePosition === 'left' ? 'lg:justify-start' : 'lg:justify-end')}
            >
              <LossePlaatjie
                src={fields?.image}
                maxwidth={1240}
                alt=""
                className="max-w-[350px] max-lg:mx-auto w-full lg:w-[428px] object-cover"
              />
            </div>
          </div>
        </div>
      </AnimateInView>
    </section>
  )
}
