import AnimateInView from '~/components/elements/AnimateInView'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBlocks({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentBlocks" className="section">
      <AnimateInView>
        <div className="container grid max-lg:grid-rows-3 lg:grid-cols-3 gap-8 lg:gap-28">
          {fields?.items &&
            fields?.items.map((item, key) => (
              <div key={key}>
                <h2 className="title--small pb-2 lg:pb-5">{item.title}</h2>
                <div>{item.description && <Content className="content">{item?.description}</Content>}</div>
              </div>
            ))}
        </div>
      </AnimateInView>
    </section>
  )
}
