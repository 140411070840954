import useGooglePlaces from '~/utils/useGooglePlaces'

export default function GooglePlaces() {
  const info = useGooglePlaces()

  if (!info?.totalRating) return null

  const stars = info.totalRating

  return (
    <div className="fixed right-0 top-1/4 bg-blitz-nougat z-50 pt-2 pb-1 px-4 -rotate-90 origin-[100%_100%] max-lg:hidden">
      <div className="flex items-center gap-3">
        <div className="font-bold text-xl">{info.totalRating},0</div>
        <div className="flex items-center gap-1">
          {Array.from({ length: stars }, (_, i) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              key={i}
              className="text-white w-[20px] h-[20px]"
            >
              <path
                d="M6.30979 0.585411C6.36966 0.401149 6.63034 0.401148 6.69021 0.58541L7.91444 4.35319C7.94121 4.4356 8.018 4.49139 8.10465 4.49139H12.0663C12.2601 4.49139 12.3406 4.73931 12.1839 4.85319L8.97882 7.18181C8.90872 7.23274 8.87939 7.32301 8.90617 7.40542L10.1304 11.1732C10.1903 11.3575 9.97937 11.5107 9.82263 11.3968L6.61756 9.06819C6.54746 9.01726 6.45254 9.01726 6.38244 9.06819L3.17738 11.3968C3.02063 11.5107 2.80974 11.3575 2.86961 11.1732L4.09383 7.40542C4.12061 7.32301 4.09128 7.23274 4.02118 7.18181L0.816113 4.85319C0.659371 4.73931 0.739925 4.49139 0.93367 4.49139H4.89535C4.982 4.49139 5.05879 4.4356 5.08556 4.35319L6.30979 0.585411Z"
                fill="currentColor"
              />
            </svg>
          ))}
        </div>
      </div>
      <a
        href="https://www.google.com/search?q=Blitz+Interieur"
        target="_blank"
        rel="noopener noreferrer"
        className="font-light text-center text-xl hover:underline"
      >
        {info.totalReviews} Google-Reviews
      </a>
    </div>
  )
}
