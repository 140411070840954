import clsx from 'clsx'
import { useInView } from 'react-hook-inview'
import AnimateInView from '~/components/elements/AnimateInView'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUsps({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true
  })

  return (
    <section data-component="ContentUsps" className="section">
      <AnimateInView>
        <div className="container py-6 lg:py-10">
          <div className="grid lg:grid-cols-2 gap-10 lg:gap-24 xl:gap-52 items-center lg:px-16">
            <div className="col-span-1">
              <h2 className="title mb-6">{fields?.title}</h2>
              <Content className="content">{fields?.description}</Content>
            </div>
            <div className="col-span-1">
              <ul className="mb-14" ref={ref}>
                {fields?.items?.map((item: any, index: number) => (
                  <li
                    key={index}
                    className={clsx(
                      'flex items-center mb-4 lg:mb-6',
                      isVisible && 'animate-fade-left animate-ease-in-out animate-duration-500',
                      isVisible ? 'opacity-100' : 'opacity-0'
                    )}
                    style={{
                      animationDelay: `${index * 100 + 250}ms`
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                      <path d="M0 10.8V6.16H6.16V0H11.2V6.16H17.36V10.8H11.2V17H6.16V10.8H0Z" fill="#DCC1AB" />
                    </svg>
                    <span className="ml-2 uppercase font-blitz-futura text-lg font-semibold">{item?.title}</span>
                  </li>
                ))}
              </ul>
              <Links fields={fields} />
            </div>
          </div>
        </div>
      </AnimateInView>
    </section>
  )
}
