import { type LoaderData, useLoaderData } from '@ubo/losse-sjedel'
import type { GooglePlace } from '~/graphql/types'

interface InfoLoaderData extends Omit<LoaderData, 'rest'> {
  rest?: {
    googlePlace?: GooglePlace
  }
}

export default function useGooglePlaces(): GooglePlace {
  const { rest } = useLoaderData<InfoLoaderData>()

  return rest?.googlePlace
}
