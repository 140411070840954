import { type LoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import Logo from '~/components/elements/Logo'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()

  return (
    <nav
      data-component="Header"
      className={clsx(
        isSticky ? 'shadow h-[80px] lg:h-[100px]' : 'h-[100px] lg:h-[140px]',
        'fixed left-0 top-0 right-0 z-50 bg-white transition-all'
      )}
    >
      <div className="container flex h-full items-center justify-between">
        <LosseLink to="/" aria-label="Home">
          {/* 0,407894736842105 */}
          <Logo className="text-blitz-shark w-[125px] h-[51px] lg:w-[152px] lg:h-[62px]" />
        </LosseLink>

        <HeaderDesktop />
        <HeaderMobile />
      </div>
    </nav>
  )
}
