import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import AnimateInView from '~/components/elements/AnimateInView'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImageAlt({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section
      data-component="ContentImageAlt"
      className="section relative lg:min-h-[500px] flex items-center max-w-[2000px] mx-auto mt-12 lg:mt-20"
    >
      <div className="hidden lg:grid lg:grid-cols-2 gap-20 lg:absolute top-0 left-0 h-full">
        <div className={clsx(fields?.imagePosition === 'right' && 'order-last', 'col-span-1')}>
          <LossePlaatjie src={fields?.image} maxwidth={2000} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="col-span-1"></div>
      </div>
      <div className="container h-full">
        <div className="grid lg:grid-cols-2 gap-8 lg:gap-20">
          <div className="col-span-1 lg:invisible">
            <LossePlaatjie src={fields?.image} maxwidth={2000} alt="" className="w-full h-full object-cover" />
          </div>
          <div className={clsx(fields?.imagePosition === 'right' && 'order-first', 'col-span-1')}>
            <AnimateInView>
              <h2 className="title mb-6">{fields?.title}</h2>
              <Content className="content">{fields?.description}</Content>
            </AnimateInView>
          </div>
        </div>
      </div>
    </section>
  )
}
