import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'react-hook-inview'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentServices({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true
  })

  return (
    <section data-component="ContentServices" className="section py-16 lg:py-24 xl:py-40 bg-blitz-shark max-w-[2000px] mx-auto">
      <div className="xl:pl-44">
        <div className="container h-full relative lg:px-24" ref={ref}>
          <h2 className="title--rotate text-white">{fields?.title}</h2>
          <div className="flex-center flex-col w-full h-full">
            <div className="w-full lg:w-1/2">
              {fields?.items?.map((item, index) => (
                <LosseLink
                  key={index}
                  to={item?.link?.url}
                  className={clsx(
                    'mb-8 lg:mb-10 last:mb-0 flex flex-wrap lg:flex-nowrap items-center relative group',
                    isVisible && 'animate-fade-left animate-ease-in-out animate-duration-500',
                    isVisible ? 'opacity-100' : 'opacity-0'
                  )}
                  style={{
                    animationDelay: `${index * 100 + 250}ms`
                  }}
                >
                  <div className="bg-white group-hover:bg-blitz-nougat transition-colors h-auto lg:h-40 w-full lg:w-[300px] flex items-end lg:absolute lg:-left-40 xl:-left-56">
                    <h3 className="title--small p-3 md:p-7 group-hover:underline">{item?.link?.title}</h3>
                  </div>
                  <LossePlaatjie
                    src={item?.image}
                    alt={item?.link?.title}
                    maxwidth={1000}
                    className="w-full lg:w-auto object-cover lg:max-w-[580px]"
                  />
                </LosseLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
