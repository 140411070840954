import { Fragment, useState } from 'react'
import FsLightbox from 'fslightbox-react'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Project as Props } from '~/graphql/types'
import { useInView } from 'react-hook-inview'
import clsx from 'clsx'

export default function Project({ data, index }: { data: Props; index: number }) {
  const [toggler, setToggler] = useState(false)
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true
  })

  if (!data.title) return null

  return (
    <>
      <FsLightbox toggler={toggler} sources={data?.recapProject?.gallery?.map((item) => item?.mediaItemUrl)} />
      <div
        role="button"
        onClick={() => setToggler(!toggler)}
        className={clsx(
          'relative cursor-pointer',
          isVisible && 'animate-fade-left animate-ease-in-out animate-duration-500',
          isVisible ? 'opacity-100' : 'opacity-0'
        )}
        ref={ref}
        style={{
          animationDelay: `${index * 100 + 250}ms`
        }}
      >
        <div className="aspect-video w-full min-h-[407px] overflow-hidden relative">
          <LossePlaatjie
            src={data.featuredImage?.node}
            className="w-full h-full object-cover transition-all hover:scale-110"
            placeholder="blur"
            maxwidth={600}
            title={data.title}
          />
        </div>
        <div className="w-full h-[245px] absolute bottom-0 left-0 flex flex-col items-start justify-end px-8 pb-12 bg-shadow">
          <h2 className="font-blitz-futura text-white uppercase text-2xl font-bold line-clamp-1">{data.title}</h2>
          <div className="flex">
            {data?.categories?.nodes?.map((item, index) => (
              <Fragment key={index}>
                <span className="mr-2 text-white font-light text-[15px] line-clamp-1">{item?.name}</span>
                {data.categories.nodes.length > 1 && <span className="text-white pr-2 last:hidden text-sm">|</span>}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
