import type { ComponentPropsWithoutRef } from 'react'
import { LosseContent } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function Content({ className, ...rest }: ComponentPropsWithoutRef<'div'>) {
  return (
    <LosseContent className={clsx('content', className)} {...rest}>
      {rest.children}
    </LosseContent>
  )
}
