import type { Transition } from 'framer-motion'
import { motion } from 'framer-motion'

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2
    }
  },
  end: {
    transition: {
      staggerChildren: 0.2
    }
  }
}

const loadingBlockTransition: Transition = {
  duration: 0.3,
  ease: 'easeInOut',
  repeat: Infinity,
  repeatType: 'reverse',
  repeatDelay: 1.5
}

export default function Loading() {
  return (
    <motion.div variants={loadingContainerVariants} initial="start" animate="end" className="w-[45px] overflow-hidden relative">
      <motion.span
        variants={{
          start: {
            width: '0%'
          },
          end: {
            width: '32%'
          }
        }}
        transition={loadingBlockTransition}
        className="bg-blitz-shark block h-[11px] border-b border-blitz-nougat ml-auto relative right-0"
      />
      <motion.span
        variants={{
          start: {
            width: '0%'
          },
          end: {
            width: '32%'
          }
        }}
        transition={loadingBlockTransition}
        className="bg-blitz-shark block h-[11px] border-b border-blitz-nougat ml-auto relative right-[18%]"
      />
      <motion.span
        variants={{
          start: {
            width: '0%'
          },
          end: {
            width: '32%'
          }
        }}
        transition={loadingBlockTransition}
        className="bg-blitz-shark block h-[11px] border-b border-blitz-nougat ml-auto relative right-[36%]"
      />
    </motion.div>
  )
}
