import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerLanding from './BannerLanding'
import BannerContact from './BannerContact'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    banner_default: BannerDefault,
    banner_landing: BannerLanding,
    banner_contact: BannerContact
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
