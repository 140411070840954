import type { Page_Flexcontent_Flex_Posts, Project as PostProps } from '~/graphql/types'
import Project from './post-types/Project'
import Links from '~/components/elements/Links'
import clsx from 'clsx'

export default function PostsLatest({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="PostsLatest" className="section">
      <div className="container">
        <h2 className="title mb-10">{fields?.title}</h2>
        <div className="flex sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 max-sm:-mr-7 max-sm:overflow-x-auto max-sm:pr-6 no-scrollbar">
          {fields?.itemsLatest?.edges?.map((edge, index) => (
            <div key={index} className={clsx('max-sm:w-[75%]', index >= 3 && 'max-lg:block max-xl:hidden')}>
              <Project data={edge?.node as PostProps} index={index} />
            </div>
          ))}
        </div>
        <Links fields={fields} className="mt-8 lg:mt-14 justify-center" />
      </div>
    </section>
  )
}
