import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import FormDefault from './FormDefault'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    form_default: FormDefault
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
