import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Banner, Page_Flexcontent_Flex_Content, Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function Links({
  type = 'normal',
  className,
  fields
}: {
  type?: 'normal' | 'alt'
  className?: string
  fields?: Page_Flexcontent_Flex_Content | Page_Flexcontent_Flex_Posts | Page_Flexcontent_Flex_Banner
}) {
  if (!fields?.links?.length) return null

  return (
    <div className={clsx('flex items-center gap-3', className)}>
      {fields?.links?.map((edge, index) => (
        <div key={index}>
          <LosseLink src={edge?.link} className={clsx(type === 'normal' && 'btn', type === 'alt' && 'btn--alt')} />
        </div>
      ))}
    </div>
  )
}
