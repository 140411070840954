import type { SwiperProps } from "swiper/react"
import { Swiper, SwiperSlide } from "swiper/react"

export const Slide = SwiperSlide

export default function Slider(props: SwiperProps) {
  return (
    <>
      <Swiper {...props}>{props.children}</Swiper>
    </>
  )
}
