import { LossePlaatjie } from '@ubo/losse-sjedel'
import AnimateInView from '~/components/elements/AnimateInView'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImages({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImages" className="section">
      <AnimateInView>
        <div className="container">
          <div className="grid max-lg:grid-rows-3 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {fields.images &&
              fields?.images.map((image, key) => (
                <LossePlaatjie
                  key={key}
                  src={image}
                  maxwidth={850}
                  alt={image?.altText}
                  className="h-[350px] lg:h-[540px] w-full object-cover"
                />
              ))}
          </div>
        </div>
      </AnimateInView>
    </section>
  )
}
