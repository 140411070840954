import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Links from '~/components/elements/Links'
import AnimateInView from '~/components/elements/AnimateInView'

export default function BannerLanding({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  function splitTitleAndAddBreak() {
    if (!fields?.title) return null

    const split = fields.title.split('|')
    if (split.length < 2) return fields.title

    return (
      <>
        {split[0]}
        <br />
        {split[1]}
      </>
    )
  }

  return (
    <section data-component="BannerLanding" className="relative h-[450px] lg:h-[calc(100vh-200px)] max-w-[2000px] mx-auto">
      <AnimateInView className="h-full">
        <LossePlaatjie loading="eager" src={fields.image} maxwidth={2000} className="absolute object-cover top-0 left-0 h-full z-0" />
        <div className="absolute left-0 top-0 h-full w-full bg-white/50 pointer-events-none z-10 lg:hidden" />
        <div className="container relative h-full flex flex-col justify-center z-20">
          <div className="lg:w-1/2 max-lg:text-center">
            <h1 className="title--large drop-shadow mb-8 lg:mb-16">{splitTitleAndAddBreak()}</h1>
            <Links className="max-lg:flex-col" fields={fields} />
          </div>
        </div>
      </AnimateInView>
    </section>
  )
}
