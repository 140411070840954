import { useState } from 'react'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { motion } from 'framer-motion'
import Content from '~/components/elements/Content'
import { Helmet } from 'react-helmet-async'
import clsx from 'clsx'
import AnimateInView from '~/components/elements/AnimateInView'
import { useInView } from 'react-hook-inview'

export default function ContentFaq({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [active, setActive] = useState<number | null>(0)
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true
  })

  function mapFaq() {
    let response = ''

    fields?.items?.forEach((item, index) => {
      response += `${index > 0 ? ',' : ''}{
        "@type": "Question",
        "name": "${item?.title}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${item?.description}"
        }
      }`
    })

    return response
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [${mapFaq()}]
          }`}
        </script>
      </Helmet>
      <section data-component="ContentFaq" className="section bg-blitz-spring-wood max-w-[2000px] mx-auto">
        <AnimateInView className="container section max-w-3xl">
          <h2 className="title--normal text-center">{fields?.title}</h2>
          <div className="mt-8" ref={ref}>
            {fields?.items?.map((item, index) => {
              const isActive = active === index

              return (
                <div
                  key={index}
                  className={clsx(isVisible ? 'animate-fade-up opacity-100' : 'opacity-0', 'border-b border-blitz-shark mt-6')}
                  style={{ animationDelay: `${index * 100 + 250}ms` }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setActive(active === index ? null : index)
                    }}
                    className="flex items-center justify-between w-full py-2 group hover:bg-blitz-nougat/50 px-2"
                  >
                    <h3 className="text-left font-bold text-blitz-shark font-blitz-futura text-xl group-hover:underline">{item?.title}</h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className={clsx(isActive ? 'rotate-0' : '-rotate-90', 'transition-all')}
                    >
                      <path
                        d="M18.7098 8.2101C18.6169 8.11638 18.5063 8.04198 18.3844 7.99121C18.2625 7.94044 18.1318 7.91431 17.9998 7.91431C17.8678 7.91431 17.7371 7.94044 17.6152 7.99121C17.4934 8.04198 17.3828 8.11638 17.2898 8.2101L12.7098 12.7901C12.6169 12.8838 12.5063 12.9582 12.3844 13.009C12.2625 13.0598 12.1318 13.0859 11.9998 13.0859C11.8678 13.0859 11.7371 13.0598 11.6152 13.009C11.4934 12.9582 11.3828 12.8838 11.2898 12.7901L6.70982 8.2101C6.61685 8.11638 6.50625 8.04198 6.38439 7.99121C6.26253 7.94044 6.13183 7.91431 5.99982 7.91431C5.8678 7.91431 5.7371 7.94044 5.61524 7.99121C5.49338 8.04198 5.38278 8.11638 5.28982 8.2101C5.10356 8.39747 4.99902 8.65092 4.99902 8.9151C4.99902 9.17929 5.10356 9.43274 5.28982 9.6201L9.87982 14.2101C10.4423 14.7719 11.2048 15.0875 11.9998 15.0875C12.7948 15.0875 13.5573 14.7719 14.1198 14.2101L18.7098 9.6201C18.8961 9.43274 19.0006 9.17929 19.0006 8.9151C19.0006 8.65092 18.8961 8.39747 18.7098 8.2101Z"
                        fill="#1D1D1B"
                      />
                    </svg>
                  </button>
                  <motion.div
                    initial="collapsed"
                    animate={active === index ? 'open' : 'collapsed'}
                    variants={{
                      open: { opacity: 1, height: 'auto' },
                      collapsed: { opacity: 0, height: 0 }
                    }}
                    className="overflow-hidden"
                  >
                    <Content className="py-3 px-2">{item?.description}</Content>
                  </motion.div>
                </div>
              )
            })}
          </div>
        </AnimateInView>
      </section>
    </>
  )
}
