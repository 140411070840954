import Configurator from '~/components/elements/Configurator.client'
import type { Page_Flexcontent_Flex_Configurator } from '~/graphql/types'
import { ClientOnly } from '~/utils/ClientOnly'

export default function ConfiguratorDefault({ fields }: { fields: Page_Flexcontent_Flex_Configurator }) {
  return (
    <section data-component="ConfiguratorDefault">
      <ClientOnly>
        {() => (
          <div className="py-5 w-full flex items-center justify-center">
            <Configurator />
          </div>
        )}
      </ClientOnly>
    </section>
  )
}
