import AnimateInView from '~/components/elements/AnimateInView'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCta({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentCta" className="section bg-blitz-nougat max-w-[2000px] mx-auto">
      <AnimateInView>
        <div className="container grid max-md:grid-rows-1 md:grid-cols-[70%,30%] max-xl:max-w-3xl xl:max-w-5xl">
          <div className="max-md:justify-self-center md:justify-start">
            <h2 className="title--small text-black max-md:mb-2 max-md:text-center">{fields.title}</h2>
            {fields.description && <Content className="content max-md:text-center max-md:mb-4">{fields?.description}</Content>}
          </div>
          <div className="max-md:justify-self-center md:justify-self-end max-md:mt-3 md:my-auto">
            {fields.links && <Links fields={fields} type="alt" />}{' '}
          </div>
        </div>
      </AnimateInView>
    </section>
  )
}
