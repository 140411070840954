/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import Logo from '../elements/Logo'
import useInfo from '~/utils/useInfo'
import useGooglePlaces from '~/utils/useGooglePlaces'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()
  const info = useInfo()
  const googlePlace = useGooglePlaces()

  const stars = []

  for (let i = 0; i < googlePlace?.totalRating; i++) {
    stars.push(i)
  }

  return (
    <footer data-component="Footer" className="bg-blitz-shark relative max-w-[2000px] mx-auto">
      <div className="container py-24">
        <LosseLink to="/">
          <Logo className="text-white" />
        </LosseLink>
        <div className="grid grid-cols-12 gap-6 mt-16">
          <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="uppercase text-white font-blitz-futura text-sm font-bold">Showroom</div>
            <a
              className="hover:underline hover:text-white text-white/75 font-light mt-1 block"
              href={info?.googleMapsUrl}
              target="_blank"
              rel="noreferrer"
            >
              {info?.addressRuleOne}
              <br />
              {info?.addressRuleTwo}
            </a>

            <div className="uppercase text-white font-blitz-futura text-sm font-bold mt-10">Contact</div>
            <div className="font-light mt-1">
              <a className="hover:underline hover:text-white text-white/75" href={`tel:${info?.phoneNumber}`}>
                {info?.phoneNumber}
              </a>
              <br />
              <a className="hover:underline hover:text-white text-white/75" href={`mailto:${info?.emailAddress}`}>
                {info?.emailAddress}
              </a>
            </div>
          </div>
          <nav className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="uppercase text-white font-blitz-futura text-sm font-bold">Menu</div>
            <ul className="mt-1 font-light">
              {footer?.menu?.map((edge, index) => (
                <li key={index}>
                  <LosseLink className="hover:underline hover:text-white text-white/75 py-1 block" src={edge?.link} />
                </li>
              ))}
              <li>
                <a href="#" className="diffuse-cookie-settings hover:underline hover:text-white text-white/75 py-1 block">
                  Cookie instellingen
                </a>
              </li>
            </ul>
          </nav>
          <nav className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="uppercase text-white font-blitz-futura text-sm font-bold">Downloads</div>
            <ul className="mt-1 font-light">
              {footer?.downloads?.map((edge, index) => (
                <li key={index}>
                  <LosseLink className="hover:underline hover:text-white text-white/75 py-1 block" src={edge?.link} />
                </li>
              ))}
            </ul>
          </nav>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="mb-4 lg:mb-6">
              {googlePlace?.totalRating && (
                <div className="flex items-center mb-1">
                  <p className="text-sm font-bold text-blitz-spring-wood mr-2 font-blitz-futura">{googlePlace?.totalRating},0</p>
                  <div className="flex items-center gap-[2px]">
                    {stars?.map((star, index) => (
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" key={index}>
                        <path
                          d="M6.30979 0.585411C6.36966 0.401149 6.63034 0.401148 6.69021 0.58541L7.91444 4.35319C7.94121 4.4356 8.018 4.49139 8.10465 4.49139H12.0663C12.2601 4.49139 12.3406 4.73931 12.1839 4.85319L8.97882 7.18181C8.90872 7.23274 8.87939 7.32301 8.90617 7.40542L10.1304 11.1732C10.1903 11.3575 9.97937 11.5107 9.82263 11.3968L6.61756 9.06819C6.54746 9.01726 6.45254 9.01726 6.38244 9.06819L3.17738 11.3968C3.02063 11.5107 2.80974 11.3575 2.86961 11.1732L4.09383 7.40542C4.12061 7.32301 4.09128 7.23274 4.02118 7.18181L0.816113 4.85319C0.659371 4.73931 0.739925 4.49139 0.93367 4.49139H4.89535C4.982 4.49139 5.05879 4.4356 5.08556 4.35319L6.30979 0.585411Z"
                          fill="#EADAC9"
                        />
                      </svg>
                    ))}
                  </div>
                </div>
              )}
              <a
                href="https://www.google.com/search?q=Blitz+Interieur"
                target="_blank"
                rel="noopener noreferrer"
                className="text-base font-light text-blitz-spring-wood hover:underline"
              >
                {googlePlace?.totalReviews} Google-Reviews
              </a>
            </div>
            <div className="flex items-center gap-4">
              <a href={info?.facebookUrl} target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                  <path
                    d="M7.54894 20V10.8777H10.6097L11.0689 7.32156H7.54894V5.05147C7.54894 4.0222 7.83359 3.32076 9.31123 3.32076L11.1928 3.31999V0.13923C10.8674 0.0969453 9.75045 0 8.45045 0C5.73586 0 3.87739 1.65697 3.87739 4.69927V7.32156H0.807373V10.8777H3.87739V20H7.54894Z"
                    fill="#F4EFEB"
                  />
                </svg>
              </a>
              <a href={info?.instagramUrl} target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <mask id="mask0_239_151" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H20.0458V20H0V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_239_151)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.7806 0H6.26228C2.80271 0 -0.00292969 2.79881 -0.00292969 6.24997V13.75C-0.00292969 17.2011 2.80271 20 6.26228 20H13.7806C17.2402 20 20.0459 17.2011 20.0459 13.75V6.24997C20.0459 2.79881 17.2402 0 13.7806 0ZM18.1661 13.7499C18.1661 16.1622 16.1989 18.1247 13.7806 18.1247H6.26225C3.84397 18.1247 1.8765 16.1622 1.8765 13.7499V6.24979C1.8765 3.8374 3.84397 1.87472 6.26225 1.87472H13.7806C16.1989 1.87472 18.1661 3.8374 18.1661 6.24979V13.7499Z"
                      fill="#F4EFEB"
                    />
                  </g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0313 5.45361C7.51249 5.45361 5.47021 7.49588 5.47021 10.0147C5.47021 12.5335 7.51249 14.5756 10.0313 14.5756C12.5501 14.5756 14.5922 12.5335 14.5922 10.0147C14.5922 7.49588 12.5501 5.45361 10.0313 5.45361ZM10.0312 12.7511C8.52278 12.7511 7.29465 11.5229 7.29465 10.0145C7.29465 8.50492 8.52278 7.27795 10.0312 7.27795C11.5396 7.27795 12.7678 8.50492 12.7678 10.0145C12.7678 11.5229 11.5396 12.7511 10.0312 12.7511Z"
                    fill="#F4EFEB"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.4167 5.45373C16.4167 5.95748 16.0083 6.36589 15.5045 6.36589C15.0008 6.36589 14.5923 5.95748 14.5923 5.45373C14.5923 4.94998 15.0008 4.5415 15.5045 4.5415C16.0083 4.5415 16.4167 4.94998 16.4167 5.45373Z"
                    fill="#F4EFEB"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:triangle sm:absolute max-sm:bg-white right-0 top-0 bottom-0 sm:w-[250px] lg:w-[300px] flex items-center justify-center max-sm:p-10 sm:items-end sm:justify-end sm:pr-10 sm:pb-20">
        <svg xmlns="http://www.w3.org/2000/svg" width="87" height="63" viewBox="0 0 87 63" fill="none">
          <g clipPath="url(#clip0_239_134)">
            <path
              d="M68.8838 34.8315C67.8668 33.4861 66.5319 32.3756 64.9004 31.4573C63.2689 30.539 61.2983 29.8556 58.9676 29.4071C61.2983 27.9549 63.0146 26.1824 64.1376 24.1108C65.2606 22.018 65.8327 19.3912 65.8327 16.2092C65.8327 10.9769 64.3495 6.96203 61.3619 4.18576C58.3743 1.40949 54.0095 0 48.2674 0H24.0913V36.9244H43.9026C47.992 36.9244 50.916 37.4583 52.6958 38.5047C54.4756 39.5512 55.3444 41.2597 55.3444 43.6088C55.3444 45.958 54.4545 47.6451 52.6958 48.7129C50.9372 49.7807 47.992 50.2932 43.9026 50.2932H0V63H24.0913H30.4055H50.1956C53.501 63 56.4886 62.6156 59.1371 61.8254C61.7857 61.0353 64.0317 59.882 65.8962 58.323C67.7396 56.7641 69.1805 54.842 70.1975 52.5569C71.2146 50.2719 71.7231 47.6664 71.7231 44.698C71.7231 42.7973 71.5112 41.0247 71.0662 39.359C70.6213 37.6932 69.9009 36.1769 68.8626 34.8529L68.8838 34.8315ZM43.3093 24.7302H40.3217V12.5359H43.3093C48.3945 12.5359 50.9372 14.5647 50.9372 18.6437C50.9372 22.7227 48.3945 24.7302 43.3093 24.7302Z"
              fill="#222323"
            />
            <path
              d="M78.7153 58.8143C78.7153 58.2377 78.8213 57.7038 79.0332 57.1913C79.245 56.6787 79.5417 56.2516 79.9231 55.8672C80.3045 55.4828 80.7494 55.1838 81.2368 54.9702C81.7453 54.7567 82.275 54.6499 82.8471 54.6499C83.4192 54.6499 83.9489 54.7567 84.4574 54.9702C84.9659 55.1838 85.3897 55.4828 85.7711 55.8672C86.1525 56.2516 86.4491 56.7001 86.661 57.1913C86.8729 57.7038 86.9788 58.2377 86.9788 58.8143C86.9788 59.3909 86.8729 59.9248 86.661 60.4374C86.4491 60.9499 86.1525 61.377 85.7711 61.7614C85.3897 62.1458 84.9448 62.4448 84.4574 62.6584C83.9489 62.8719 83.4192 62.9787 82.8471 62.9787C82.275 62.9787 81.7453 62.8719 81.2368 62.6584C80.7282 62.4448 80.3045 62.1458 79.9231 61.7614C79.5417 61.377 79.245 60.9285 79.0332 60.4374C78.8213 59.9248 78.7153 59.3909 78.7153 58.8143Z"
              fill="#222323"
            />
          </g>
        </svg>
      </div>
    </footer>
  )
}
