import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Slider, { Slide } from '~/components/elements/Slider'
import AnimateInView from '~/components/elements/AnimateInView'

export default function ContentLogos({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentLogos" className="section">
      <AnimateInView>
        <div className="container">
          <h2 className="title mb-10 lg:mb-16">{fields?.title}</h2>
          <Slider
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              500: {
                slidesPerView: 3,
                spaceBetween: 30
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40
              },
              1024: {
                slidesPerView: 7,
                spaceBetween: 30
              }
            }}
            className="lg:!px-16"
          >
            {fields?.items?.map((item: any, index: number) => (
              <Slide key={index}>
                <LossePlaatjie src={item?.image} maxwidth={1240} alt={item?.image?.altTex || `Slider logo ${index + 1}`} />
              </Slide>
            ))}
          </Slider>
        </div>
      </AnimateInView>
    </section>
  )
}
