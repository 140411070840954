import { LosseBlogBink, LossePlaatjie, type LosseBlogBinkReadableParams, useLosseBlogBink } from '@ubo/losse-sjedel'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Project from './post-types/Project'
import AnimateInView from '~/components/elements/AnimateInView'
import clsx from 'clsx'

export const READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'categorie',
  direction: 'volgorde',
  sort: 'sortering',
  cursor: 'pagina',
  query: 'zoekterm'
}

export default function PostsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.itemsOverview?.pageInfo) {
    return null
  }

  return (
    <section data-component="PostsOverview" className="max-w-[2000px] mx-auto relative">
      <LosseBlogBink pageInfo={fields.itemsOverview?.pageInfo as Maybe<WpPageInfo> | undefined} readableParams={READABLE_PARAMS}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.itemsOverview?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Project: Project
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { isFilterActive, toggleFilter } = useLosseBlogBink()
  const postTypeName = getPostTypeName(fields)

  return (
    <>
      <div className="container h-[450px] lg:h-[650px] flex flex-col justify-end lg:justify-center">
        <AnimateInView>
          <div className="p-6 lg:p-9 bg-blitz-sand h-fit max-h-[370px] w-full lg:w-1/2">
            <h1 className="title--normal mt-3 mb-6 lg:mb-20">{fields?.title}</h1>
            <div className="flex flex-wrap gap-3 pb-6 lg:pb-8">
              {fields?.filters?.map((filter) => {
                const isActive = isFilterActive(filter?.name)

                return (
                  <button
                    key={filter?.databaseId}
                    type="button"
                    onClick={() => toggleFilter(filter?.name)}
                    className={clsx(
                      isActive ? 'bg-white !text-blitz-shark' : 'bg-blitz-shark',
                      'py-[10px] px-6 text-white rounded-full hover:bg-white hover:text-blitz-shark transition duration-300 ease-in-out uppercase'
                    )}
                  >
                    {filter?.name}
                  </button>
                )
              })}
            </div>
          </div>
        </AnimateInView>
        <div>
          {fields?.image && (
            <div className="h-full w-full max-h-[650px] lg:w-[60%] overflow-hidden absolute top-0 right-0 -z-10">
              <LossePlaatjie
                loading="eager"
                placeholder="blur"
                className="w-full h-[450px] lg:h-full object-cover"
                src={fields.image}
                maxwidth={2000}
              />
            </div>
          )}
        </div>
      </div>
      <div className="container py-10 md:py-20 lg:py-32">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-6 lg:gap-y-14">
          {fields?.itemsOverview?.edges?.map((edge, index) => {
            if (!edge || !edge.node) return null

            const Component = PostTypes[postTypeName] || PostTypes.Post

            return (
              <div key={edge.node.uri} className="col-span-1">
                <Component data={edge.node} index={index} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
