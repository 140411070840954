import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()

  return (
    <>
      {header?.menu?.map((edge, index) => (
        <li key={index}>
          <LosseLink
            className={clsx(
              'max-lg:animate-fade-left',
              index === header?.menu.length - 1
                ? 'btn xl:ml-4 max-lg:mt-6'
                : 'uppercase hover:underline max-lg:font-bold text-3xl lg:text-sm xl:text-base font-blitz-futura font-normal'
            )}
            src={edge?.link}
            style={{
              animationDelay: `${index * 150}ms`
            }}
          />
        </li>
      ))}
    </>
  )
}
