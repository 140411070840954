import type { PropsWithChildren } from 'react'
import { LosseLayout, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header/Header'
import clsx from 'clsx'
import GooglePlaces from './GoogleReviews'

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <LosseLayout>
      <Content>{children}</Content>
    </LosseLayout>
  )
}

function Content({ children }: PropsWithChildren<{}>) {
  const { isSticky } = useLosseLayout()

  return (
    <>
      <Header />
      <main className={clsx('min-h-screen-dynamic', isSticky ? 'pt-[80px] lg:pt-[100px]' : 'pt-[100px] lg:pt-[140px]')}>{children}</main>
      <Footer />
      <GooglePlaces />
    </>
  )
}
