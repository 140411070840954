import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Links from '~/components/elements/Links'
import AnimateInView from '~/components/elements/AnimateInView'
import useInfo from '~/utils/useInfo'

export default function BannerContact({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const info = useInfo()

  return (
    <section data-component="BannerContact" className="max-w-[2000px] mx-auto relative lg:h-[650px] lg:mb-20 max-lg:flex max-lg:flex-col">
      <div className="section container h-full flex items-center order-2">
        <AnimateInView>
          <div className="p-6 lg:px-12 lg:py-9 bg-blitz-sand h-fit w-full lg:w-1/2 lg:min-w-[642px] max-lg:-mt-[200px]">
            <div>
              <h1 className="title--normal mt-3 mb-4 lg:mb-10">{fields?.title}</h1>
              <div className="flex flex-wrap gap-4 sm:gap-8 md:gap-32 font-light">
                <div>
                  <p>
                    <strong className="font-bold">Showroom</strong>
                  </p>
                  <a className="hover:underline" href={info?.googleMapsUrl} target="_blank" rel="noreferrer">
                    <p>{info?.addressRuleOne}</p>
                    <p>{info?.addressRuleTwo}</p>
                  </a>
                  <p>{info?.addressRuleExtra}</p>
                </div>
                <div>
                  <p>
                    <strong className="font-bold">Contact</strong>
                  </p>
                  <a href={`tel:${info?.phoneNumber}`} className="hover:underline block">
                    {info?.phoneNumber}
                  </a>
                  <a href={`mailto:${info?.emailAddress}`} className="hover:underline block">
                    {info?.emailAddress}
                  </a>
                </div>
              </div>
            </div>
            <Links className="mt-6 lg:my-12" fields={fields} type="alt" />
          </div>
        </AnimateInView>
      </div>
      {fields?.image && (
        <div className="h-[450px] lg:h-[650px] w-full lg:w-[60%] overflow-hidden lg:absolute top-0 right-0 -z-10 order-1">
          <LossePlaatjie loading="eager" placeholder="blur" className="w-full h-full object-cover" src={fields.image} maxwidth={2000} />
        </div>
      )}
    </section>
  )
}
