import { useMatches } from '@ubo/losse-sjedel'
import type { FormDuufProps } from '@ubo/form-duuf'
import Loading from './Loading'
import Content from './Content'
import FormDuuf from '@ubo/form-duuf'

type Props = Omit<FormDuufProps, 'renderStatus' | 'renderLoading'> & {
  renderStatus?: FormDuufProps['renderStatus']
  renderLoading?: FormDuufProps['renderLoading']
}

export default function Form(props: Props) {
  const matches = useMatches()
  const formId = props.data.formId
  const apiUrl = `${matches[0].data.config.wordpressUrl}/wp-json/gf/v2/forms/${formId}`

  return (
    <FormDuuf
      apiUrl={apiUrl}
      renderLoading={() => (
        <div className="absolute left-0 top-0 w-full h-full bg-blitz-spring-wood flex--center gap-3 z-10">
          <div className="flex justify-center">
            <Loading />
          </div>
          <Content className="mt-4 text-center">
            <p>Aan het versturen ...</p>
          </Content>
        </div>
      )}
      renderStatus={({ content }) => (
        <>
          <div className="flex--center">
            <div className="bg-blitz-nougat flex--center w-16 h-16 rounded-full mx-auto">
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16 text-white relative left-1"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.0303 8.78039L8.99993 16.8107L5.4696 13.2804L6.53026 12.2197L8.99993 14.6894L15.9696 7.71973L17.0303 8.78039Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <Content className="mt-4 text-center">{content}</Content>
        </>
      )}
      className="relative"
      {...props}
    />
  )
}
