import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Form from '~/components/elements/Form'
import AnimateInView from '~/components/elements/AnimateInView'
import { useState } from 'react'
import clsx from 'clsx'

export default function FormDefault({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [isDone, setIsDone] = useState(false)

  return (
    <section data-component="FormDefault" className="section bg-blitz-spring-wood max-w-[2000px] mx-auto" id="formulier">
      <AnimateInView className="container lg:py-10 lg:px-0 max-w-3xl">
        {!isDone && <h2 className="title--normal text-center">{fields?.title}</h2>}
        {!isDone && <h3 className="title--small capitalize mt-3 text-center">{fields?.subtitle}</h3>}
        <div className={clsx(!isDone && 'mt-8 lg:mt-16', 'relative')}>
          <Form data={fields.form} onSubmitDone={() => setIsDone(true)} />
        </div>
      </AnimateInView>
    </section>
  )
}
