import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentServices from './ContentServices'
import ContentUsps from './ContentUsps'
import ContentLogos from './ContentLogos'
import ContentBackgroundImage from './ContentBackgroundImage'
import ContentImage from './ContentImage'
import ContentImages from './ContentImages'
import ContentQuote from './ContentQuote'
import ContentCta from './ContentCta'
import ContentBlocks from './ContentBlocks'
import ContentFaq from './ContentFaq'
import ContentImageAlt from './ContentImageAlt'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    content_services: ContentServices,
    content_usps: ContentUsps,
    content_logos: ContentLogos,
    content_background_image: ContentBackgroundImage,
    content_image: ContentImage,
    content_image_alt: ContentImageAlt,
    content_images: ContentImages,
    content_quote: ContentQuote,
    content_cta: ContentCta,
    content_blocks: ContentBlocks,
    content_faq: ContentFaq
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
