import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import AnimateInView from '~/components/elements/AnimateInView'

export default function ContentQuote({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentQuote" className="section">
      <AnimateInView>
        <div className="container w-full md:w-3/4 lg:w-4/6 max-w-[850px] text-center">
          <div className="flex justify-center mx-auto pb-8">
            <svg width="66" height="49" viewBox="0 0 66 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M66 35.4537C66 37.787 65.3973 39.9907 64.1918 42.0648C63.0294 44.1389 61.3718 45.8241 59.2192 47.1204C57.1096 48.3735 54.7847 49 52.2446 49C46.9061 49 42.7945 46.9043 39.91 42.713C37.0254 38.5216 35.5832 33.7901 35.5832 28.5185C35.5832 25.6235 36.1429 22.6636 37.2622 19.6389C38.3816 16.571 40.1252 13.6975 42.4932 11.0185C44.9041 8.33951 48.1115 6.00617 52.1155 4.01852C56.1624 2.03086 60.7906 0.691358 66 0V4.14815C62.2544 5.57407 59.6067 7.84259 58.0568 10.9537C56.5499 14.0216 55.7965 17.5 55.7965 21.3889V22.6852C62.5988 24.6296 66 28.8858 66 35.4537ZM30.4168 35.4537C30.4168 37.787 29.8141 39.9907 28.6086 42.0648C27.4462 44.1389 25.7887 45.8241 23.636 47.1204C21.5264 48.3735 19.2016 49 16.6614 49C11.3229 49 7.21135 46.9043 4.32681 42.713C1.44227 38.5216 0 33.7901 0 28.5185C0 25.6235 0.559687 22.6636 1.67906 19.6389C2.79843 16.571 4.54207 13.6975 6.90998 11.0185C9.32094 8.33951 12.5284 6.00617 16.5323 4.01852C20.5793 2.03086 25.2074 0.691358 30.4168 0V4.14815C26.6712 5.57407 24.0235 7.84259 22.4736 10.9537C20.9667 14.0216 20.2133 17.5 20.2133 21.3889V22.6852C27.0157 24.6296 30.4168 28.8858 30.4168 35.4537Z"
                fill="#EADAC9"
              />
            </svg>
          </div>
          {fields.description && <Content className="content children-h2:font-blitz-futura children-h2:text-black">{fields?.description}</Content>}
        </div>
      </AnimateInView>
    </section>
  )
}
