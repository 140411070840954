import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import AnimateInView from '~/components/elements/AnimateInView'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section
      data-component="BannerDefault"
      className="max-w-[2000px] mx-auto relative lg:h-[650px] max-lg:flex max-lg:flex-col max-lg:-mb-[50px]"
    >
      <div className="section container h-full flex items-center lg:mb-20 mb-10 order-2">
        <AnimateInView className="w-full">
          <div className="p-6 lg:p-9 bg-blitz-sand h-fit w-full lg:max-w-[50%] lg:min-w-[50%] max-lg:-mt-[200px]">
            <div>
              <h1 className="title mt-5 lg:mt-10 mb-6">{fields?.title}</h1>
              {fields.description && <Content className="content">{fields?.description}</Content>}
            </div>
            <Links className="mt-6 lg:mt-12 mb-5 lg:mb-10 max-lg:flex-col" fields={fields} type="alt" />
          </div>
        </AnimateInView>
      </div>
      {fields?.image && (
        <div className="h-[400px] sm:h-[450px] lg:h-[650px] w-full lg:w-[60%] overflow-hidden lg:absolute top-0 right-0 -z-10 order-1">
          <LossePlaatjie loading="eager" placeholder="blur" className="w-full h-full object-cover" src={fields.image} maxwidth={2000} />
        </div>
      )}
    </section>
  )
}
